import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";

const DuplicateModal = ({ onClose, action, isOpen, name }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="medium">
          {name || "New Form"} has been created.
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Created {name} as a new form with no projects tied to it.
        </ModalBody>

        <ModalFooter>
          <Stack direction="row" spacing={4} w="100%">
            <Button variant="secondary" onClick={onClose} flex="1" size="lg">
              Not Now
            </Button>
            <Button variant="primary" onClick={action} flex="2" size="lg">
              View Forms
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DuplicateModal;
