import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  FormErrorMessage,
  useColorMode,
  Icon,
  Input,
  Text,
  useColorModeValue,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
  Select,
  Button,
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import Card from "components/card/Card";
import { useAuth } from "contexts/AppContext";
import { ArrowLeft2 } from "iconsax-react";
import { useGetExperts } from "services/useExperts";
import { useAddProject } from "services/useProjects";
import { useNavigate } from "react-router-dom";

function NewProject() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "#2D3748";

  const { colorMode } = useColorMode();
  const { mutate: addProjectMutation, isPending } = useAddProject();
  const navigate = useNavigate();
  const { data: experts = [] } = useGetExperts({ enabled: true }).data || {};

  const customerId = new URLSearchParams(window.location.search).get(
    "customer_id"
  );

  const steps = [
    { title: "Customer Info", description: "" },
    { title: "Project Details", description: "" },
    { title: "Inspection", description: "" },
  ];

  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const validationSchema = Yup.object().shape({
    project_name: Yup.string().required("Project name is required"),
    project_address: Yup.string().required("Project address is required"),
    expert: Yup.string().nullable(),
  });

  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Flex
        onClick={handleCancelClick}
        cursor={"pointer"}
        zIndex={10}
        color="primary.primary"
        alignItems="center"
      >
        <Icon as={ArrowLeft2} fontSize="24px" marginRight={2} />
        <Text fontWeight={500} fontSize={22}>
          Add New Project
        </Text>
      </Flex>

      <Flex
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "20px" }}
        flexDirection="column"
      >
        <Card
          marginTop="0"
          mb="20px"
          w={{ base: "95%", md: "800px" }}
          mx={{ base: "10px", md: "60px" }}
        >
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <VStack>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </VStack>
                <StepSeparator mb={7} />
              </Step>
            ))}
          </Stepper>
        </Card>

        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={{
            base: "transparent",
            md: colorMode === "dark" ? "gray.700" : "white",
          }}
          p={{ base: 4, md: "30px" }}
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Project Details
            </Text>
            <Text
              mb={{ base: 4, md: "36px" }}
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter project details below.
            </Text>
          </Box>

          <Formik
            initialValues={{
              project_name: "",
              project_address: "",
              expert: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              addProjectMutation(
                {
                  address: values.project_address,
                  name: values.project_name,
                  expert_id: experts ? values.expert : null,
                  description: "_description",
                  customer_id: customerId,
                  customer: {
                    id: customerId,
                  },
                },
                {
                  onSuccess: ({ data }) => {
                    navigate(
                      `/admin/start-inspection?id=${data.data.project.id}`
                    );
                  },
                  onError: () => {
                    alert("Error in adding project");
                  },
                }
              );

              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <VStack alignItems="start" gap={4}>
                  {!experts && (
                    <Flex
                      bgColor="#E4EAF6"
                      p={{ base: 4, md: "30px" }}
                      borderRadius="15px"
                    >
                      <Text fontSize="lg" fontWeight="normal">
                        Your company does not have any Experts yet. We have
                        auto-assigned you as the Expert for this project. You
                        can change it later from Projects.
                      </Text>
                    </Flex>
                  )}
                  <Field name="project_address">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.project_address &&
                          form.touched.project_address
                        }
                      >
                        <FormLabel htmlFor="project_address">
                          Project Address *
                        </FormLabel>
                        <Input
                          {...field}
                          id="project_address"
                          placeholder="Write project address here"
                          size="lg"
                          backgroundColor="#fff"
                        />
                        <FormErrorMessage>
                          {form.errors.project_address}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="project_name">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.project_name && form.touched.project_name
                        }
                      >
                        <FormLabel htmlFor="project_name">
                          Project Name *
                        </FormLabel>
                        <Input
                          {...field}
                          id="project_name"
                          placeholder="Write project name here"
                          size="lg"
                          backgroundColor="#fff"
                        />
                        <FormErrorMessage>
                          {form.errors.project_name}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {experts && (
                    <Field name="expert">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel htmlFor="expert">
                            Assign an Expert (Optional)
                          </FormLabel>
                          <Select
                            {...field}
                            id="expert"
                            placeholder="Select expert"
                            width="100%"
                            textColor={textColor}
                            size="lg"
                          >
                            {experts.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  )}
                </VStack>

                <HStack mt={6}>
                  <Button
                    variant="secondary"
                    type="button"
                    size="lg"
                    flex="1"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    flex="3"
                    size="lg"
                    isLoading={isPending}
                    isDisabled={
                      !(
                        props.touched.project_name ||
                        props.touched.project_address
                      ) ||
                      !!(
                        props.errors.project_name ||
                        props.errors.project_address
                      )
                    }
                  >
                    Save and Continue
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </>
  );
}

export default NewProject;
