import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Card,
  Button,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { columns } from "../../FormDetails/data/columns";
import { Add, Minus } from "iconsax-react";
import { useAddForm } from "services/useForms";

const StepOneSchema = Yup.object().shape({
  name: Yup.string().required("Form Name is required"),
});

const StepTwoSchema = Yup.object().shape({
  section: Yup.string(),
});

const TwoStepForm = ({ activeStep, setActiveStep }) => {
  console.log(activeStep);

  const [showAddSection, setShowAddSection] = useState(false);
  const navigate = useNavigate();
  const { mutate } = useAddForm();

  const initialValues = {
    name: "",
    section: "",
  };

  const handleNext = (values, actions) => {
    actions.setTouched({});
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1);
    } else {
      navigate("/admin/forms");
    }
  };

  const handleSubmit = (values) => {
    mutate(values, {
      onSuccess: () => {
        navigate("/admin/forms");
      },
      onError: (e) => {
        console.error(e);
      },
    });
  };

  const validationSchemas = [StepOneSchema, StepTwoSchema];

  return (
    <Box
      w="100%"
      mx="auto"
      p={{ base: 0, md: 6 }}
      px={{ base: "16px", md: 6 }}
      borderRadius="16px"
      backgroundColor={{ base: "transparent", md: "#fff" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={(values, actions) => {
          if (activeStep === 2) {
            handleSubmit(values);
          } else {
            handleNext(values, actions);
          }
        }}
      >
        {({ isValid }) => (
          <Form>
            <VStack spacing={4}>
              {activeStep === 1 && (
                <>
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>Form Name*</FormLabel>
                        <Input
                          {...field}
                          placeholder="Enter Form Name"
                          backgroundColor="#fff"
                          size="lg"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </>
              )}

              {activeStep === 2 && (
                <>
                  {columns.map((column) => (
                    <Card w="100%" px="24px" py="12px" key={column}>
                      {column}
                    </Card>
                  ))}
                  <Button
                    style={{ marginRight: "auto" }}
                    variant="ghost"
                    px="8px"
                    fontWeight="medium"
                    rightIcon={showAddSection ? <Minus /> : <Add />}
                    onClick={() => setShowAddSection(!showAddSection)}
                  >
                    Add Another Section
                  </Button>
                  {showAddSection && (
                    <Field name="section">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.section && form.touched.section
                          }
                        >
                          <FormLabel>Section</FormLabel>
                          <Input
                            {...field}
                            size="lg"
                            placeholder="Enter Section"
                            backgroundColor="#fff"
                          />
                          <FormErrorMessage>
                            {form.errors.section}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                </>
              )}
            </VStack>

            <Box
              mt={6}
              display="flex"
              justifyContent="space-between"
              bottom="100px"
              gap="16px"
              w="100%"
            >
              <Button
                size="lg"
                onClick={handleBack}
                variant="secondary"
                flex="1"
              >
                {activeStep > 1 ? "Back" : "Cancel"}
              </Button>
              <Button
                size="lg"
                type="submit"
                variant="primary"
                isDisabled={!isValid}
                flex="2"
              >
                {activeStep === 1 ? "Save and Next" : "Submit"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TwoStepForm;
