import api from "./apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Adds a new company
 *
 * @param {Object} body
 * @param {File} body.logo
 * @param {string} body.name
 * @param {string} body.phone_number
 * @param {string} body.address
 * @param {string} body.description
 * @returns {Promise}
 */
const addCompany = async ({
  logo,
  name,
  phone_number,
  address,
  description,
}) => {
  const formData = new FormData();
  formData.append("logo", logo);
  formData.append("name", name);
  formData.append("phone_number", phone_number);
  formData.append("address", address);
  formData.append("description", description);

  const response = await api.post(API_URLS.onboarding, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const useAddCompany = () => {
  return useMutation({
    mutationFn: addCompany,
  });
};
