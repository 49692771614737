import React, { useEffect, useRef } from "react";
import { columns } from "../data/columns";
import { Button, Card, Flex } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const Columns = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const buttonRefs = useRef({});

  const updateQuery = (column) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("column", column);
    setSearchParams(newParams);
  };

  useEffect(() => {
    const activeColumn = searchParams.get("column");
    if (activeColumn && buttonRefs.current[activeColumn]) {
      buttonRefs.current[activeColumn].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  }, [searchParams]);

  return (
    <Card
      p="24px"
      my="24px"
      color="primary.primary"
      mx={{ base: "16px", md: "0" }}
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        overflowX="auto"
        overflowY="hidden"
        whiteSpace="nowrap"
      >
        {columns.map((column) => {
          const active = column === searchParams.get("column");
          return (
            <Button
              key={column}
              ref={(el) => (buttonRefs.current[column] = el)}
              variant="outline"
              border="2px"
              borderColor="transparent"
              onClick={() => {
                updateQuery(column);
              }}
              borderRadius={0}
              borderBottom={active && "2px solid"}
              flex="0 0 auto"
              px="0"
              mr="16px"
              fontWeight={active ? "medium" : "normal"}
            >
              {column}
            </Button>
          );
        })}
      </Flex>
    </Card>
  );
};

export default Columns;
