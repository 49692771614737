import React from "react";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultAuth from "layouts/auth/Default";
import {
  expertValidationSchema,
  editExpertInitialValues,
} from "./validationSchema";
import illustration from "assets/img/auth/auth.png";
import { useEditExpert } from "services/useExperts";
import { useDeleteExpert } from "./delete_expert/useDeleteExpert";
import DeleteExpert from "./delete_expert/delete_expert";
import { Arrow, ArrowLeft2, Trash } from "iconsax-react";

function EditExpert() {
  const textColor = useColorModeValue("navy.700", "white");
  const location = useLocation();
  const expert = location.state?.expert || {};
  const expertInitial = expert ? expert : editExpertInitialValues;
  const { colorMode } = useColorMode();
  const { mutate, isPending } = useEditExpert();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: onCloseDeleteModal,
    onDelete,
    isLoading: isDeleteLoading,
    error: deleteError,
  } = useDeleteExpert();

  const navigate = useNavigate();

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSubmit = (values) => {
    const payload = {
      id: expert?.id,
      ...values,
    };
    mutate(payload, {
      onSuccess: () => {
        navigate("/admin/experts");
      },
      onError: (e) => {
        console.error(e);
      },
    });
  };

  return (
    <>
      <Flex justifyContent="space-between" mt="4px">
        <HStack
          onClick={handleCancelClick}
          cursor="pointer"
          color="primary.primary"
        >
          <Icon as={ArrowLeft2} fontSize="24px" />
          <Text fontWeight={500} fontSize="xl">
            Edit Expert
          </Text>
        </HStack>
        <Button
          onClick={openDeleteModal}
          rightIcon={<Trash />}
          color={{ base: "primary.primary", md: "red" }}
          variant="tertiary"
        >
          {useBreakpointValue({
            base: "",
            md: "Delete this Expert",
          })}
        </Button>
      </Flex>

      <Flex
        mx="auto"
        me="auto"
        alignItems="center"
        justifyContent={{ base: "flex-start", md: "center" }}
        mb={{ base: "0", md: "60px" }}
        mt={{ base: "0", md: "20px" }}
        flexDirection="column"
        background={{ base: "#F5F5F5", md: "white" }}
        w={{ base: "100%", md: "800px" }}
        h={{ base: "100%", md: "auto" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", lg: "800px" }}
          maxW="100%"
          p={{ base: "16px", md: "30px" }}
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto" hideBelow={"md"}>
            <Text color="primary.primary" fontSize="2xl" mb="10px">
              {expertInitial.first_name || "Edit Expert"}
            </Text>
          </Box>
          <Formik
            initialValues={expertInitial}
            validationSchema={expertValidationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <VStack spacing={3} alignItems="start">
                  <Flex width="100%" alignContent="space-between">
                    <HStack align="start" spacing={5} width="100%">
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="first_name" color={textColor}>
                              First Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="first_name"
                              placeholder="First Name"
                              borderRadius="10px"
                              backgroundColor="white"
                            />
                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="last_name" color={textColor}>
                              Last Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="last_name"
                              placeholder="Last Name"
                              borderRadius="10px"
                              backgroundColor="white"
                            />
                            <FormErrorMessage>
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </HStack>
                  </Flex>
                  <Field name="phone">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                      >
                        <FormLabel htmlFor="phone" color={textColor}>
                          Phone Number *
                        </FormLabel>
                        <InputGroup>
                          <InputLeftAddon>+1</InputLeftAddon>
                          <Input
                            {...field}
                            id="phone"
                            placeholder="(000) 000 0000"
                            type="tel"
                            maxLength={10}
                            borderRadius="10px"
                            backgroundColor="white"
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel htmlFor="email" color={textColor}>
                          Email *
                        </FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="ex: name@company.com"
                          borderRadius="10px"
                          backgroundColor="white"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </VStack>

                <HStack mt={10}>
                  <Button
                    variant="secondary"
                    w="30%"
                    size="lg"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    w="70%"
                    size="lg"
                    type="submit"
                    isLoading={isPending}
                  >
                    Save Changes
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <DeleteExpert
          isOpen={isDeleteModalOpen}
          onClose={onCloseDeleteModal}
          onDelete={() => onDelete(expert.id)}
          error={deleteError}
          expertName={expert.first_name + " " + expert.last_name}
          isLoading={isDeleteLoading}
        />
      )}
    </>
  );
}

export default EditExpert;
