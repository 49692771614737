import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import {
  Flex,
  VStack,
  HStack,
  useColorModeValue,
  Text,
  Card,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Checkbox,
  Select,
  Box,
} from "@chakra-ui/react";
import CButton from "components/fields/Button";
import {
  billingAddressValidationSchema,
  addressInitialValues,
} from "./validationSchema";
import { useAddBillingInfo } from "services/usePayment";
import { useGetCities, useGetStates } from "services/useLocation";

function BillingAddressForm({ nextStep }) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const { mutate, isPending } = useAddBillingInfo();
  const textColor = useColorModeValue("navy.700", "white");

  const { data: statesData, isLoading: isStatesLoading } = useGetStates({
    enabled: !!selectedCountry,
    countryName: selectedCountry,
  });

  const { data: citiesData, isLoading: isCitiesLoading } = useGetCities({
    enabled: !!selectedState,
    cityId: selectedState,
  });

  const handleSubmit = (values) => {
    mutate(values, {
      onSuccess: () => {
        nextStep();
      },
      onError: (error) => {
        // what are possible error to see how you can handle this part better
        toast({
          title: "Error",
          description:
            "There was an error adding your Billing Address. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        // formik.setFieldError("general", error.message || "Update failed");
      },
    });
  };

  return (
    <Card
      p={8}
      borderRadius="20px"
      shadow="md"
      mb={8}
      width={{ base: "100%", lg: "90%" }}
    >
      <Text fontSize="28px" mb={4}>
        Billing Address
      </Text>
      <Formik
        initialValues={addressInitialValues}
        validationSchema={billingAddressValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <VStack spacing={4} align="start">
              <Field name="company_name">
                {({ field }) => (
                  <FormControl>
                    <FormLabel htmlFor="company_name" color={textColor}>
                      Company Name *
                    </FormLabel>
                    <Input {...field} id="company_name" />
                  </FormControl>
                )}
              </Field>
              <Field name="country">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.country && form.touched.country}
                  >
                    <FormLabel htmlFor="country" color={textColor}>
                      Country *
                    </FormLabel>
                    <Select
                      {...field}
                      id="country"
                      placeholder="Choose your country"
                      onChange={(e) => {
                        const country = e.target.value;
                        setFieldValue("country", country);
                        setFieldValue("state", "");
                        setFieldValue("city", "");
                        setSelectedCountry(country);
                        setSelectedState(null);
                      }}
                    >
                      <option value="US">United States</option>
                      {/* <option value="CA">Canada</option> */}
                    </Select>
                    <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="address_line_1">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.address_line_1 && form.touched.address_line_1
                    }
                  >
                    <FormLabel htmlFor="address_line_1" color={textColor}>
                      Address Line 1 *
                    </FormLabel>
                    <Input
                      {...field}
                      id="address_line_1"
                      placeholder="Write your address here"
                    />
                    <FormErrorMessage>
                      {form.errors.address_line_1}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="address_line_2">
                {({ field }) => (
                  <FormControl>
                    <FormLabel htmlFor="address_line_2" color={textColor}>
                      Address Line 2
                    </FormLabel>
                    <Input
                      {...field}
                      id="address_line_2"
                      placeholder="Write your address here"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="state">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.state && form.touched.state}
                  >
                    <FormLabel htmlFor="state" color={textColor}>
                      State *
                    </FormLabel>
                    <Select
                      {...field}
                      id="state"
                      placeholder="Choose your state"
                      onChange={(e) => {
                        const state = e.target.value;
                        setFieldValue("state", state);
                        setFieldValue("city", "");
                        setSelectedState(state);
                      }}
                      isDisabled={!values.country || isStatesLoading}
                    >
                      {statesData?.map((state) => (
                        <option key={state.state_id} value={state.state_id}>
                          {state.state_name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex
                width="100%"
                alignContent="space-between"
                marginBottom={"30px"}
              >
                <HStack align="start" spacing={5} width="100%">
                  <Field name="city">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.city && form.touched.city}
                        flex="1"
                      >
                        <FormLabel htmlFor="city" color={textColor}>
                          City *
                        </FormLabel>
                        <Select
                          {...field}
                          id="city"
                          placeholder="Choose your city"
                          isDisabled={!values.state || isCitiesLoading}
                        >
                          {citiesData?.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.city}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="zip_or_postal_code">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.zip_or_postal_code &&
                          form.touched.zip_or_postal_code
                        }
                        flex="1"
                      >
                        <FormLabel
                          htmlFor="zip_or_postal_code"
                          color={textColor}
                        >
                          ZIP or Postal Code *
                        </FormLabel>
                        <Input
                          {...field}
                          id="zip_or_postal_code"
                          placeholder="12345"
                        />
                        <FormErrorMessage>
                          {form.errors.zip_or_postal_code}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </HStack>
              </Flex>
              <Box pt={6}>
                <Checkbox
                  colorScheme="blue"
                  isChecked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                >
                  By filling this checkbox I accepted the Sales Contract.
                </Checkbox>
              </Box>
            </VStack>
            <Flex justifyContent={"end"} mt={10} gap={4}>
              <CButton
                variant="outline"
                px={{ base: "20px", md: "40px" }}
                onClick={() => navigate("/onboarding/plans")}
              >
                Cancel
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                h="50"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                px={{ base: "20px", md: "40px" }}
                isDisabled={!isChecked}
                isLoading={isPending}
              >
                Continue To Payment
              </CButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default BillingAddressForm;
