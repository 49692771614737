import api from "./apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Add a new form
 *
 * @param {Object} body
 * @param {string} body.name
 * @param {string} body.description
 * @returns {Promise<Object>}
 */
const addForm = async (body) => {
  const response = await api.post(API_URLS.forms, body);
  return response;
};

export const useAddForm = () => {
  return useMutation({
    mutationFn: addForm,
  });
};

/**
 * Duplicate a form
 *
 * @param {Object} body
 * @param {string} body.id
 * @returns {Promise<Object>}
 */
const duplicateForm = async (body) => {
  const response = await api.post(`${API_URLS.forms}/duplicate/ipsum`, body);

  return response;
};

export const useDuplicateForm = () => {
  return useMutation({
    mutationFn: duplicateForm,
  });
};

/**
 * Get all forms
 *
 * @returns {Promise<Object>}
 */
export const getAllForms = async () => {
  const { data } = await api.get(`${API_URLS.forms}`);
  return data.data;
};

export const useGetAllForms = ({ enabled }) => {
  return useQuery({
    queryKey: ["allForms"],
    enabled,
    queryFn: () => getAllForms(),
    keepPreviousData: true,
  });
};

export const getFormById = async ({ id }) => {
  const { data } = await api.get(`${API_URLS.forms}/${id}`);
  return data.data;
};

export function useGetFormById({ id }) {
  return useQuery({
    queryKey: ["form", id],
    enabled: true,
    queryFn: () => getFormById({ id }),
    keepPreviousData: true,
  });
}
