import api from "./apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Adds a new project
 *
 * @param {Object} payload
 * @param {string} payload.name
 * @param {string} payload.address
 * @param {string} payload.expert_id
 * @param {string} payload.description
 * @param {string} payload.customer_id
 * @param {Object} payload.customer
 * @param {string} payload.customer.id
 * @param {string} payload.customer.first_name
 * @param {string} payload.customer.last_name
 * @param {string} payload.customer.phone
 * @param {string} payload.customer.email
 * @returns {Promise<Object>}
 */
const addProject = async (payload) => {
  const response = await api.post(API_URLS.add_projects, payload);
  return response;
};

export const useAddProject = () => {
  return useMutation({
    mutationFn: addProject,
  });
};

/**
 * Edits a project
 *
 * @param {Object} payload
 * @param {string} payload.id
 * @param {string} payload.name
 * @param {string} payload.address
 * @param {string} payload.expert_id
 * @param {string} payload.description
 * @param {string} payload.customer_id
 * @returns {Promise<Object>}
 */
const editProject = async (payload) => {
  const { id, ...body } = payload || {};
  const response = await api.put(`${API_URLS.edit_projects}/${id}`, body);
  return response;
};

export const useEditProject = () => {
  return useMutation({
    mutationFn: editProject,
  });
};

/**
 * Deletes a project
 *
 * @param {string} projectId
 * @returns {Promise<Object>}
 */
const deleteProject = async (projectId) => {
  const response = await api.delete(`${API_URLS.edit_projects}/${projectId}`);
  return response;
};

export const useDeleteProject = () => {
  return useMutation({
    mutationFn: deleteProject,
  });
};

/**
 * Gets all projects
 *
 * @returns {Promise<Object>}
 */
export const getAllProjects = async (page = 1, perPage = 10) => {
  const { data } = await api.get(`${API_URLS.all_projects}`);
  return data.data.projects;
};

export const useGetAllProjects = ({ enabled, page, perPage }) => {
  return useQuery({
    queryKey: ["allProjects", page, perPage],
    enabled,
    queryFn: () => getAllProjects(page, perPage),
    keepPreviousData: true,
  });
};

/**
 * Edits the inspection status of a project
 *
 * @param {Object} payload
 * @param {string} payload.id
 * @param {number} payload.status_index
 * @returns {Promise<Object>}
 */
const editProjectInspectionStatus = async (payload) => {
  const { id, status_index } = payload || {};
  const response = await api.patch(`${API_URLS.all_projects}/${id}/status`, {
    status_index,
  });

  return response;
};

export const useEditProjectInspectionStatus = () => {
  return useMutation({
    mutationFn: editProjectInspectionStatus,
  });
};
