import api from "./apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Edits an expert
 *
 * @param {Object} payload
 * @param {string} payload.id
 * @param {string} payload.first_name
 * @param {string} payload.last_name
 * @param {string} payload.email
 * @param {string} payload.phone
 * @returns {Promise<Object>}
 */
const editExpert = async (payload) => {
  const { id, ...body } = payload || {};
  const response = await api.put(`${API_URLS.experts}/${id}`, body);

  return response;
};

export const useEditExpert = () => {
  return useMutation({
    mutationFn: editExpert,
  });
};

/**
 * Retrieves an expert by ID
 *
 * @param {Object} payload
 * @param {string} payload.id
 * @returns {Promise<Object>}
 */
const getExpert = async ({ id }) => {
  const { data } = await api.get(`${API_URLS.experts}/${id}`);
  return data?.data || {};
};

export const useGetExpertById = ({ enabled, id }) => {
  return useQuery({
    queryKey: ["expert"],
    enabled,
    queryFn: () => getExpert({ id }),
  });
};

const getExperts = async ({ page = 1, perPage = 10 }) => {
  const { data } = await api.get(API_URLS.experts);
  return data;
};

export const useGetExperts = ({ enabled, page, perPage }) => {
  return useQuery({
    queryKey: ["experts", page, perPage],
    enabled,
    queryFn: () => getExperts({ page, perPage }),
  });
};

/**
 * Invites experts
 *
 * @param {Object} body
 * @param {Array<string>} body.emails
 * @returns {Promise<Object>}
 */
const inviteExperts = async (body) => {
  const response = await api.post(API_URLS.invite_expert, body);
  return response;
};

export const useInviteExperts = () => {
  return useMutation({
    mutationFn: inviteExperts,
  });
};
