import api from "./apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Adds a new customer
 *
 * @param {Object} body
 * @param {string} body.first_name
 * @param {string} body.last_name
 * @param {string} body.email
 * @param {string} body.phone
 * @param {string} body.customer_id
 * @returns {Promise}
 */
const addCustomers = async (body) => {
  const response = await api.post(API_URLS.customers, body);
  return response;
};

export const useAddCustomers = () => {
  return useMutation({
    mutationFn: addCustomers,
  });
};

/**
 * Edits a customer
 *
 * @param {Object} payload
 * @param {string} payload.id
 * @param {string} payload.first_name
 * @param {string} payload.last_name
 * @param {string} payload.email
 * @param {string} payload.phone
 * @returns {Promise}
 */
const editCustomer = async (payload) => {
  const { id, ...body } = payload || {};
  const response = await api.put(`${API_URLS.all_customers}/${id}`, body);

  return response;
};

export const useEditCustomer = () => {
  return useMutation({
    mutationFn: editCustomer,
  });
};

/**
 * Deletes a customer
 *
 * @param {string} customerId
 * @returns {Promise<Object>}
 */
const deleteCustomer = async (customerId) => {
  const response = await api.delete(`${API_URLS.customer}/${customerId}`);
  return response;
};

export const useDeleteCustomer = () => {
  return useMutation({
    mutationFn: deleteCustomer,
  });
};

/**
 * Retrieves a paginated list of customers
 *
 * @param {number} [page=1]
 * @param {number} [perPage=10]
 * @returns {Promise<Object>}
 */
const getAllCustomers = async (page = 1, perPage = 10) => {
  const { data } = await api.get(
    `${API_URLS.customers}?page=${page}&per_page=${perPage}`
  );

  return data;
};

export const useGetAllCustomers = ({ enabled, page, perPage }) => {
  return useQuery({
    queryKey: ["allCustomers", page, perPage],
    enabled,
    queryFn: () => getAllCustomers(page, perPage),
    keepPreviousData: true,
  });
};
