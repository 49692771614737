import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  InputLeftAddon,
  InputGroup,
  Button,
  Spinner,
  FormControl,
  FormErrorMessage,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { useDeleteCustomer } from "./delete_customer/useDeleteCustomer";
import DeleteCustomer from "./delete_customer/delete_customer";
import { useGetAllCustomers, useEditCustomer } from "services/useCustomers";
import {
  customerInitialValues,
  customerValidationSchema,
} from "./validationSchema";
import { useNavigate } from "react-router-dom";
import { ArrowLeft2, Trash } from "iconsax-react";

function EditCustomer() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { colorMode } = useColorMode();
  const [initialValues, setInitialValues] = useState({});

  const { data } = useGetAllCustomers({ enabled: true, perPage: 1000 });
  const customers = data?.data || [];

  const navigate = useNavigate();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
    onDelete: deleteCustomer,
    error: deleteError,
    isLoading: deleteLoading,
  } = useDeleteCustomer();
  const { mutate } = useEditCustomer();

  const customerId = new URLSearchParams(window.location.search).get(
    "customer-id"
  );
  const customer = customers.find((customer) => customer.id == customerId);

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...values,
      id: customerId,
      phone: `+1${values?.phone}`,
    };
    mutate(payload, {
      onSuccess: () => {
        navigate("/admin/customers");
      },
      onError: (error) => {
        console.error(error);
      },
      onSettled: () => setSubmitting(false),
    });
  };

  useEffect(() => {
    setInitialValues({
      first_name: customer?.first_name,
      last_name: customer?.last_name,
      phone: customer?.phone.startsWith("+1")
        ? customer?.phone.slice(2)
        : customer?.phone,
      email: customer?.email,
      id: customer?.id,
    });
  }, [customer]);

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <>
      <Flex justifyContent="space-between" mt="4px">
        <HStack
          onClick={handleCancelClick}
          cursor="pointer"
          color="primary.primary"
        >
          <Icon as={ArrowLeft2} fontSize="24px" />
          <Text fontWeight={500} fontSize="xl">
            Edit Customer
          </Text>
        </HStack>
        <Button
          onClick={openDeleteModal}
          rightIcon={<Trash />}
          color={{ base: "primary.primary", md: "red" }}
          variant="tertiary"
        >
          {useBreakpointValue({
            base: "",
            md: "Delete this Customer",
          })}
        </Button>
      </Flex>

      <Flex
        mx="auto"
        me="auto"
        alignItems="center"
        justifyContent={{ base: "flex-start", md: "center" }}
        mb={{ base: "0", md: "60px" }}
        mt={{ base: "0", md: "7vh" }}
        flexDirection="column"
        background={{ base: "#F5F5F5", md: "white" }}
        w={{ base: "100%", md: "800px" }}
        h={{ base: "100%", md: "auto" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto" hideBelow={"md"}>
            <Text color={textColor} fontSize="28px" mb="10px">
              Customer Details
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Edit your customer information
            </Text>
          </Box>
          {!customer ? (
            <Spinner size={"lg"} alignSelf={"center"} />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={customerValidationSchema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <VStack spacing={6} alignItems="start">
                    <Flex width="100%" alignContent="space-between">
                      <HStack align="start" spacing={5} width="100%">
                        <Field name="first_name">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.first_name &&
                                form.touched.first_name
                              }
                              flex="1"
                            >
                              <FormLabel htmlFor="first_name" color={textColor}>
                                First Name *
                              </FormLabel>
                              <Input
                                {...field}
                                id="first_name"
                                placeholder="Write customer first name"
                                size="lg"
                                backgroundColor="white"
                              />
                              <FormErrorMessage>
                                {form.errors.first_name}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="last_name">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.last_name && form.touched.last_name
                              }
                              flex="1"
                            >
                              <FormLabel htmlFor="last_name" color={textColor}>
                                Last Name *
                              </FormLabel>
                              <Input
                                {...field}
                                id="last_name"
                                placeholder="Write customer last name"
                                size="lg"
                                backgroundColor="white"
                              />
                              <FormErrorMessage>
                                {form.errors.last_name}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Flex>
                    <Field name="phone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <FormLabel htmlFor="phone" color={textColor}>
                            Phone Number *
                          </FormLabel>
                          <InputGroup size="lg">
                            <InputLeftAddon>+1</InputLeftAddon>
                            <Input
                              {...field}
                              id="phone"
                              placeholder="(000) 000 0000"
                              type="tel"
                              maxLength={10}
                              backgroundColor="white"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email" color={textColor}>
                            Email *
                          </FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="ex: name@company.com"
                            size="lg"
                            backgroundColor="white"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </VStack>

                  <HStack mt={10}>
                    <Button
                      size="lg"
                      flex="1"
                      variant="secondary"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      size="lg"
                      flex="3"
                      isLoading={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </HStack>
                </Form>
              )}
            </Formik>
          )}
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <DeleteCustomer
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onDelete={() => deleteCustomer(customerId)}
          error={deleteError}
          isLoading={deleteLoading}
        />
      )}
    </>
  );
}

export default EditCustomer;
