// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  VStack,
  Text,
  Image,
  Circle,
  Button,
} from "@chakra-ui/react";

// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import emptyIcon from "assets/img/layout/empty-project.png";
import React from "react";
import CheckTable from "views/admin/_default/components/CheckTable";
import ComplexTable from "views/admin/_default/components/ComplexTable";
import DailyTraffic from "views/admin/_default/components/DailyTraffic";
import PieCard from "views/admin/_default/components/PieCard";
import Tasks from "views/admin/_default/components/Tasks";
import TotalSpent from "views/admin/_default/components/TotalSpent";
import WeeklyRevenue from "views/admin/_default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/_default/variables/columnsData";
import tableDataCheck from "views/admin/_default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/_default/variables/tableDataComplex.json";
import { Add } from "iconsax-react";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <VStack spacing="50px" align="center" maxW="400px" textAlign="center">
        <Image src={emptyIcon}></Image>
        <Text fontSize="2xl" fontWeight="bold">
          No Project Yet
        </Text>
        <Text
          fontSize="md"
          textAlign="center"
          color="#6f6f6f"
          whiteSpace="normal"
        >
          You haven’t created any project yet! Once you do, they will be
          displayed on this page.
        </Text>
        <Button rightIcon={<Add />} variant="primary">
          Create New Project
        </Button>
      </VStack>
    </Box>
  );
}
