import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowLeft2, DocumentCopy, Setting2 } from "iconsax-react";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Columns from "./components/Columns";
import Question from "./components/Question";
import DuplicateModal from "./components/DuplicateModal";
import { useDuplicateForm, useGetFormById } from "services/useForms";

const FormDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { data, isLoading } = useGetFormById(id);

  const duplicateId = searchParams.get("duplicateId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isPending } = useDuplicateForm();
  const [duplicateFormName, setDuplicateFormName] = useState("");

  const updateQuery = (id) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("duplicateId", id);
    setSearchParams(newParams);
  };

  const createDuplicate = () => {
    mutate(
      { id },
      {
        onSuccess: (values) => {
          setDuplicateFormName(values?.data?.data?.form?.name);
          updateQuery(values?.data?.data?.form?.id);
          setIsModalOpen(true);
        },
        onError: (e) => {
          console.error(e);
        },
      }
    );
  };

  return (
    <Box color="primary.primary">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        backgroundColor={{ base: "#fff", md: "transparent" }}
        h="48px"
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/forms")}
        >
          <ArrowLeft2 fontSize={24} />
          <Text hideBelow="md">Form Details - </Text>
          {data?.form?.name || id}
        </Heading>
        <Stack direction="row">
          <Button
            variant="secondary"
            size="lg"
            border={{ base: 0, md: "1px" }}
            fontWeight="medium"
            px={{ base: 0, md: "24px" }}
            rightIcon={<DocumentCopy />}
            onClick={createDuplicate}
            isLoading={isPending}
          >
            {useBreakpointValue({
              base: "",
              md: "Duplicate This Form",
            })}
          </Button>
          <Button
            bgColor={{ base: "transparent", md: "#203764" }}
            textColor={{ base: "#203764", md: "#fff" }}
            rightIcon={<Setting2 />}
            variant={{ base: "tertiary", md: "primary" }}
            border="1px solid transparent"
            fontWeight="medium"
            px={{ base: 0, md: "24px" }}
            size="lg"
          >
            {useBreakpointValue({
              base: "",
              md: "  Form Settings",
            })}
          </Button>
        </Stack>
      </Flex>

      <Columns />

      <Question number="1" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="2" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="3" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="4" title="Can you see Cracks/ Gaps on the surface?" />
      <Question number="5" title="Can you see Cracks/ Gaps on the surface?" />

      <DuplicateModal
        onClose={() => setIsModalOpen(false)}
        action={() => {
          navigate(`?id=${duplicateId}&column=General+Information`);
          window.location.reload();
        }}
        isOpen={isModalOpen}
        name={duplicateFormName}
      />
    </Box>
  );
};

export default FormDetails;
