// Chakra imports
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";
import { ArrowRight2, Calendar, Location } from "iconsax-react";

import React from "react";
// Assets
import { useNavigate } from "react-router-dom";

export default function Project(props) {
  const { title, status, address, update, expert, id, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bg = useColorModeValue("white", "navy.700");
  const navigate = useNavigate();

  return (
    <Card bg={bg} {...rest} p="24px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        {/* <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' /> */}
        <Box mt={{ base: "10px", md: "0" }} width="100%">
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            mb="10px"
          >
            <Text color={textColorPrimary} fontWeight="700" fontSize="md">
              {title}
            </Text>
            <ProjectStatusBadge status={status} />
          </Box>
          <Flex alignItems="center" gap={2} py={2}>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="xs"
              me="4px"
              my="5px"
              display="flex"
              alignItems="center"
              gap={1}
              width={16}
            >
              <Location variant="Bold" size={16} />
              Address
            </Text>
            :
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="xs"
              me="4px"
              my="5px"
              isTruncated
            >
              {address}
            </Text>
          </Flex>
          {/* <Flex alignItems="center" gap={2} py={2}>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
              width={20}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <Profile2User variant="Bold" />
              Expert
            </Text>
            :
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              {expert}
            </Text>
          </Flex> */}
          <Flex alignItems="center" gap={2} py={2}>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="xs"
              me="4px"
              my="5px"
              width={16}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <Calendar variant="Bold" size={16} />
              Update
            </Text>
            :
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="xs"
              me="4px"
              my="5px"
            >
              {update}
            </Text>
          </Flex>
          <Box
            position="absolute"
            right="0"
            bottom="0"
            pr={4}
            pb={7}
            display={"flex"}
            alignItems={"center"}
            fontWeight={400}
            cursor={"pointer"}
            fontSize="sm"
            onClick={() =>
              navigate(`/admin/projects/project-details?projectId=${id}`)
            }
          >
            View <ArrowRight2 size={16} />
          </Box>
        </Box>
      </Flex>
    </Card>
  );
}
