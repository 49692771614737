import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLeftElement,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  InputGroup,
  Skeleton,
  Text,
  Select,
  Button,
  Input,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";
import {
  Add,
  ArrowRight2,
  Calendar,
  Clock,
  DocumentFilter,
  Icon,
  Location,
  People,
  Profile2User,
} from "iconsax-react";
import { useGetAllProjects } from "services/useProjects";
import Project from "views/admin/projects/components/Project";
import { useUserPermission } from "hooks/useUserPermission";

export default function Projects() {
  const { data: projects = [], isLoading } = useGetAllProjects({
    enabled: true,
  });
  const textColor = useColorModeValue("navy.700", "white");
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const navigate = useNavigate();
  const cardShadow = useColorModeValue(
    "0px 2px 2px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const canAddOrRemoveProject = useUserPermission();

  const handleSearch = (searchTerm, projectsList) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return projectsList.filter((item) => {
      return (
        item.address?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.company?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.expert?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.customer?.first_name
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        item.customer?.last_name?.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  };

  const filteredProjects = useMemo(() => {
    return searchText ? handleSearch(searchText, projects) : projects;
  }, [searchText, projects]);

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-US");

  const handleCreateNewProject = () => navigate("/admin/new-customer");

  const handleProjectDetails = (id) =>
    navigate("/admin/projects/project-details?projectId=" + id);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const statusFilteredProjects = useMemo(() => {
    if (!selectedStatus) return filteredProjects;
    return filteredProjects.filter(
      (project) => project.status === selectedStatus
    );
  }, [selectedStatus, filteredProjects]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight={500}
          color={"#203764"}
        >
          Projects
        </Heading>
        {canAddOrRemoveProject && (
          <>
            <Button
              size="lg"
              rightIcon={<Add />}
              variant={{ base: "tertiary", md: "primary" }}
              onClick={handleCreateNewProject}
              hideBelow="md"
            >
              Add New Project
            </Button>
            <Button
              variant="tertiary"
              onClick={handleCreateNewProject}
              hideFrom="md"
              pr="0"
            >
              <Add size={26} />
            </Button>
          </>
        )}
      </Box>

      <Flex
        maxW={{ base: "auto", md: "650px" }}
        gap={2}
        mx={{ base: 4, md: 0 }}
      >
        <FormControl w="100%" flex="2">
          <InputGroup display="block">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" boxSize={6} mt={1} ml={3} />
            </InputLeftElement>
            <Input
              bgColor="#fff"
              fontSize="sm"
              paddingLeft="45px"
              type="text"
              placeholder="Search"
              size="lg"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              h="44px"
            />
          </InputGroup>
        </FormControl>
        <Select
          placeholder="All Status"
          value={selectedStatus}
          onChange={handleStatusChange}
          h="44px"
          backgroundColor="#fff"
          flex="1.2"
          size="sm"
          borderRadius="8px"
        >
          <option value="inspection">Inspection</option>
          <option value="evaluation">Evaluation</option>
          <option value="not_started">Not Started</option>
          <option value="completed">Completed</option>
        </Select>
      </Flex>

      <Box
        overflowX="auto"
        display={{ base: "none", md: "block" }}
        mt={8}
        p={0}
        borderRadius={15}
        backgroundColor="white"
        boxShadow="sm"
      >
        <TableContainer>
          <Table variant="simple" colorScheme="brand" size="sm">
            <Thead h={16}>
              <Tr>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    <Calendar variant="Bold" size={16} />
                    Project Name
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    {" "}
                    <Location variant="Bold" size={16} />
                    Address
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    <DocumentFilter variant="Bold" size={16} />
                    Total EEE Count
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textAlign="center"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    {" "}
                    <Clock variant="Bold" size={16} />
                    Project Status
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    <Calendar variant="Bold" size={16} />
                    Inspection Date
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    <Profile2User variant="Bold" size={16} />
                    Expert
                  </Flex>
                </Th>
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  textTransform={"none"}
                  fontSize="sm"
                  fontWeight="500"
                >
                  <Flex alignItems="center" gap={1}>
                    <People variant="Bold" size={16} />
                    Customer
                  </Flex>
                </Th>
                <Th bg="#EBEFFF" textColor={textColor}></Th>
              </Tr>
            </Thead>

            <Tbody>
              {isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <Tr key={index} h={12}>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))
                : statusFilteredProjects.map((item) => (
                    <Tr
                      key={item.id}
                      onClick={() => handleProjectDetails(item.id)}
                      h={16}
                      cursor="pointer"
                      _hover={{ bg: "#f7f7f7" }}
                    >
                      <Td fontSize="sm">{item.name}</Td>
                      <Td fontSize="sm">{item.address?.slice(0, 20)} ...</Td>
                      <Td fontSize="sm" textAlign="center">
                        0
                      </Td>
                      <Td fontSize="sm" textAlign="center">
                        <ProjectStatusBadge status={item.status} />
                      </Td>
                      <Td fontSize="sm">{formatDate(item.created_at)}</Td>
                      <Td fontSize="sm">
                        {item.expert?.name?.slice(0, 10)} ...
                      </Td>
                      <Td fontSize="sm">{`${item.customer?.first_name} ${item.customer?.last_name}`}</Td>
                      <Td fontWeight={"500"}>
                        <Flex alignItems={"center"} gap={1}>
                          View <ArrowRight2 size={18} />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        <Flex direction="column" gap={2} m={4}>
          {statusFilteredProjects.map((project) => (
            <Project
              key={project.id}
              boxShadow={cardShadow}
              mb="10px"
              address={project.address}
              update={formatDate(project.updated_at)}
              title={project.name}
              status={project.status}
              onClick={() => handleProjectDetails(project.id)}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
