import {
  Button,
  Card,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Edit } from "iconsax-react";
import React from "react";

const Question = ({ number, title }) => {
  return (
    <Card
      p="24px"
      my="24px"
      color="primary.primary"
      mx={{ base: "16px", md: "0" }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text>
          {number}. {title}
        </Text>
        <Button fontWeight="normal" variant="ghost" rightIcon={<Edit />}>
          {useBreakpointValue({
            base: "",
            md: "Edit Question",
          })}
        </Button>
      </Stack>
    </Card>
  );
};

export default Question;
