import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch } from "react-redux";
import { any } from "store/localActions";
// Chakra imports
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Link,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
  FormControl,
  FormErrorMessage,
  Select,
  Button,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

// Custom components
import DefaultAuth from "layouts/auth/Default";
import { HSeparator } from "components/separator/Separator";

import {
  customerInitialValues,
  customerValidationSchema,
} from "./validationSchema";

// Assets
import illustration from "assets/img/auth/auth.png";
import Card from "components/card/Card";
import { useAddCustomers, useGetAllCustomers } from "services/useCustomers";
import { ArrowLeft2 } from "iconsax-react";

function NewCustomer() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const { mutate, isPending } = useAddCustomers();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const hasParent = location.state?.hasParent;

  const [selectedItem, setSelectedItem] = useState("");
  const [customerInfo, setCustomerInfo] = useState(customerInitialValues);
  const { data, isLoading: isCustomerLoading } = useGetAllCustomers({
    enabled: !hasParent,
  });
  const customers = data?.data || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const steps = [
    { title: "Customer Info", description: "" },
    { title: "Project Details", description: "" },
    { title: "Inspection", description: "" },
  ];

  const { activeStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSelectChange = (event) => {
    const selectedCustomerId = event.target.value;
    setSelectedItem(selectedCustomerId);

    if (selectedCustomerId) {
      const selectedCustomer = customers.find(
        (customer) => customer.id === selectedCustomerId
      );

      if (selectedCustomer) {
        setCustomerInfo({
          first_name: selectedCustomer.first_name,
          last_name: selectedCustomer.last_name,
          phone: selectedCustomer.phone.slice(2),
          email: selectedCustomer.email,
          id: selectedCustomer.id,
        });
      }
    } else {
      setCustomerInfo(customerInitialValues);
    }
  };

  const handleSubmit = (values) => {
    if (selectedItem) {
      const customer = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        customer_id: customerInfo.id,
      };
      dispatch(any(customer));
      navigate(`/admin/new-project?customer_id=${customerInfo.id}`);
    } else {
      const customer = {
        ...values,
        phone: `+1${values.phone}`,
      };
      mutate(customer, {
        onSuccess: (response) => {
          if (!hasParent) {
            navigate(
              `/admin/new-project?customer_id=${response.data.data.customer.id}`
            );
          } else navigate("/admin/customers");
        },
        onError: (e) => {
          console.error(e);
        },
      });
    }
  };

  return (
    <>
      {!hasParent && (
        <Flex
          onClick={handleCancelClick}
          cursor={"pointer"}
          zIndex={10}
          color="primary.primary"
          alignItems="center"
        >
          <Icon as={ArrowLeft2} fontSize="24px" marginRight={2} />
          <Text fontWeight={500} fontSize={22}>
            Add Customer
          </Text>
        </Flex>
      )}

      <Flex
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "10px", md: "20px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "3vh" }}
        flexDirection="column"
      >
        {!hasParent && (
          <Card
            marginTop="0"
            mb="20px"
            w={{ base: "95%", md: "800px" }}
            mx={{ base: "10px", md: "60px" }}
          >
            <Stepper index={activeStep}>
              {steps.map((step) => (
                <Step key={step.title}>
                  <VStack>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </VStack>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Card>
        )}
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", lg: "800px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Add Customer
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter customer details below.
            </Text>
          </Box>
          <Formik
            initialValues={customerInfo}
            validationSchema={customerValidationSchema(!!selectedItem)}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <VStack spacing={3} alignItems="start">
                  <Flex width="100%" alignContent="space-between">
                    <HStack align="start" spacing={5} width="100%">
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="first_name" color={textColor}>
                              First Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="first_name"
                              isDisabled={!!selectedItem}
                              placeholder="Write customer first name"
                              size="lg"
                            />
                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                            flex="1"
                          >
                            <FormLabel htmlFor="last_name" color={textColor}>
                              Last Name *
                            </FormLabel>
                            <Input
                              {...field}
                              id="last_name"
                              isDisabled={!!selectedItem}
                              placeholder="Write customer last name"
                              size="lg"
                            />
                            <FormErrorMessage>
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </HStack>
                  </Flex>
                  <Field name="phone">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                      >
                        <FormLabel htmlFor="phone" color={textColor}>
                          Phone Number *
                        </FormLabel>
                        <InputGroup size="lg">
                          <InputLeftAddon>+1</InputLeftAddon>
                          <Input
                            {...field}
                            id="phone"
                            isDisabled={!!selectedItem}
                            placeholder="(000) 000 0000"
                            type="tel"
                            maxLength={10}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel htmlFor="email" color={textColor}>
                          Email *
                        </FormLabel>
                        <Input
                          {...field}
                          id="email"
                          isDisabled={!!selectedItem}
                          placeholder="ex: name@company.com"
                          size="lg"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {!hasParent && (
                    <Flex align="center" my="45px" width={"100%"}>
                      <HSeparator />
                      <Text fontSize={18} color="gray.400" mx="20px">
                        or
                      </Text>
                      <HSeparator />
                    </Flex>
                  )}

                  {!hasParent && (
                    <Box width="100%">
                      <FormLabel color={textColor}>
                        <Text>Choose a Customer</Text>
                      </FormLabel>
                      <Select
                        w={{ base: "100%", gl: "700px" }}
                        textColor={textColor}
                        value={selectedItem}
                        size="lg"
                        onChange={handleSelectChange}
                        disabled={isCustomerLoading}
                        placeholder="Select a customer"
                      >
                        {customers.length ? (
                          customers.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.first_name + " " + item.last_name}
                            </option>
                          ))
                        ) : (
                          <option value="">No customers available</option>
                        )}
                      </Select>
                    </Box>
                  )}
                </VStack>

                <HStack mt={10}>
                  <Button
                    variant="secondary"
                    size="lg"
                    onClick={handleCancelClick}
                    flex="1"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    flex="3"
                    size="lg"
                    isLoading={isPending}
                  >
                    Save and Continue
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </>
  );
}

export default NewCustomer;
