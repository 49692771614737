/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useChangePassword } from "services/useAuth";
import illustration from "assets/img/auth/auth.png";
import { useNavigate } from "react-router-dom";

function NewPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const errorMessage = "#F2415A";
  const { colorMode } = useColorMode();

  //------------------

  const { mutate: changePasswordMutate } = useChangePassword();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const userState = useSelector((state) => state.auth);

  const handleNewPassword = async (e) => {
    setIsDisabled(true);
    e.preventDefault();
    if (password == confirm_password) {
      changePasswordMutate(
        {
          email: userState.user.username,
          otp: userState.user.otp,
          password: password,
          password_confirmation: confirm_password,
        },
        {
          onSuccess: () => {
            navigate("/auth/sign-in");
          },
          onError: () => {
            setIsDisabled(false);
            setError("Invalid email");
          },
        }
      );
    } else {
      setError("Password not match");
    }
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto" mb="30px">
            <Text color={textColor} fontSize="28px" mb="10px">
              New Password
            </Text>
            <Text fontSize="12px">Set a new password for your account.</Text>
          </Box>
          <form onSubmit={handleNewPassword}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="password"
              >
                New Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="password"
                placeholder="********"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={password}
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="confirm_password"
              >
                Repeat New Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="password"
                placeholder="********"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={confirm_password}
                id="confirm_password"
                name="confirm_password"
                onChange={(e) => setConfirm_password(e.target.value)}
              />

              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
              >
                Update Password
              </CButton>
            </FormControl>
          </form>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <NavLink to="/auth/sign-in">
              <Text as="span" ms="5px" fontWeight="500">
                Back to Sign In
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default NewPassword;
