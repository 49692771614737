/* eslint-disable */
import React, { useRef, useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  Link,
  Text,
  useColorModeValue,
  VStack,
  Heading,
  HStack,
  Button,
  InputGroup,
  InputLeftAddon,
  Image,
} from "@chakra-ui/react";
import CButton from "components/fields/Button";
import { ArrowLeft2, Image as ImageIcon, Trash } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEditProfile, useGetUser } from "services/useProfile";

function EditProfile() {
  const textColor = useColorModeValue("navy.700", "white");
  const navigate = useNavigate();

  const [base64Image, setBase64Image] = useState("");
  const inputFileRef = useRef(null);

  const userState = useSelector((state) => state.auth);

  const { mutate, isPending, isError, status, error } = useEditProfile(
    userState.user.token
  );

  const { data: userData, isLoading: initialLoading } = useGetUser({
    token: userState.user.token,
    staleTime: 0,
  });

  const companyData = userData?.data?.data?.company;

  const formik = useFormik({
    initialValues: {
      company_name: companyData?.name || "",
      company_phone: companyData?.phone_number || "",
      company_address: companyData?.address || "",
      email: companyData?.creator?.email || "",
      description: companyData?.description || "",
      company_logo: companyData?.logo || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_name: Yup.string().required("Company name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      company_phone: Yup.string().nullable(),
      company_address: Yup.string().nullable(),
      description: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          navigate("/admin/profile");
        },
        onError: (error) => {
          formik.setFieldError("general", error.message || "Update failed");
        },
      });
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("company_logo", file);
      formik.setFieldTouched("company_logo", true);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => inputFileRef.current.click();

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        px={{ base: "16px", md: 0 }}
        h="48px"
        backgroundColor={{ base: "#fff", md: "initial" }}
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={1}
          cursor="pointer"
          onClick={() => navigate("/admin/profile")}
          color="primary.primary"
        >
          <ArrowLeft2 fontSize={24} />
          Edit Profile
        </Heading>
        <CButton variant="ghost" px={{ base: 0, md: "initial" }}>
          <Flex alignItems={"center"} justifyContent={"space-evenly"}>
            <Text fontWeight={100} fontSize={16} color={"red"} hideBelow={"md"}>
              Delete Account
            </Text>
            <Icon
              as={Trash}
              color={{ base: "primary.primary", md: "red" }}
              pl={{ base: 0, md: 2 }}
              fontSize={{ base: 24, md: 32 }}
            />
          </Flex>
        </CButton>
      </Flex>

      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        flexDirection="column"
      >
        <Flex
          direction="column"
          w={{ base: "auto", md: "558px" }}
          background={{ base: "transparent", md: "#fff" }}
          px={{ base: "16px", md: "30px" }}
          py={{ base: 0, md: "30px" }}
          borderRadius="15px"
        >
          <form onSubmit={formik.handleSubmit}>
            <VStack
              my={{ base: 0, md: 4 }}
              mb={{ base: 4, md: 0 }}
              spacing={0}
              alignItems="start"
            >
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="company_name"
              >
                Company Name *
              </FormLabel>
              <Input
                id="company_name"
                name="company_name"
                type="text"
                variant="auth"
                placeholder="Enter the company name"
                borderRadius={6}
                backgroundColor="#fff"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={initialLoading}
                isInvalid={
                  formik.touched.company_name && formik.errors.company_name
                }
              />
            </VStack>
            <VStack my={4} spacing={0} alignItems="start">
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="email"
              >
                Email
              </FormLabel>
              <HStack w="100%">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  variant="auth"
                  placeholder="info@drbalcony.com"
                  backgroundColor="#fff"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && formik.errors.email}
                  borderRadius={6}
                  disabled
                />
                <CButton
                  variant="outline"
                  size="sm"
                  color="primary.primary"
                  cursor="not-allowed"
                  py="19px"
                  isDisabled
                >
                  Change
                </CButton>
              </HStack>
            </VStack>

            <VStack my={4} spacing={0} alignItems="start">
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="company_phone"
              >
                Phone Number
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>+1</InputLeftAddon>
                <Input
                  maxLength={10}
                  id="company_phone"
                  name="company_phone"
                  type="tel"
                  variant="auth"
                  placeholder="Enter phone number"
                  backgroundColor="#fff"
                  value={formik.values.company_phone}
                  onChange={formik.handleChange}
                  disabled={initialLoading}
                  borderRadius={6}
                />
              </InputGroup>
            </VStack>

            <VStack my={4} spacing={0} alignItems="start">
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="company_address"
              >
                Address
              </FormLabel>
              <Input
                id="company_address"
                name="company_address"
                type="text"
                variant="auth"
                placeholder="Enter company address"
                backgroundColor="#fff"
                disabled={initialLoading}
                value={formik.values.company_address}
                onChange={formik.handleChange}
                borderRadius={6}
              />
            </VStack>
            <VStack my={4} spacing={0} alignItems="start">
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="description"
              >
                Description
              </FormLabel>
              <Input
                id="description"
                name="description"
                type="text"
                variant="auth"
                placeholder="Enter description"
                backgroundColor="#fff"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={initialLoading}
                borderRadius={6}
              />
            </VStack>
            <VStack my={4} spacing={0} alignItems="start">
              <FormLabel fontSize="sm" fontWeight="500" color={textColor}>
                Company Logo (Optional)
              </FormLabel>
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Box
                  mr="15px"
                  align="center"
                  cursor="pointer"
                  onClick={handleClick}
                >
                  {!base64Image ? (
                    <Flex gap={2} textAlign="left">
                      <Icon
                        as={ImageIcon}
                        w="72px"
                        h="72px"
                        p="16px"
                        color="#718096"
                        borderRadius="20px"
                        border=".5px #718096 solid"
                      />
                      <Box w={{ base: "auto", md: "180px" }}>
                        <Text>Logo final</Text>
                        <Text fontSize="xs" color="text.paragraph">
                          PNG or JPG. Square images under 128kb work best
                        </Text>
                      </Box>
                    </Flex>
                  ) : (
                    <Flex gap={2} textAlign="left">
                      <Image w="72px" h="72px" src={base64Image} />
                      <Box w="180px">
                        <Text>Logo final</Text>
                        <Text fontSize="xs" color="text.paragraph">
                          PNG or JPG. Square images under 128kb work best
                        </Text>
                      </Box>
                    </Flex>
                  )}
                </Box>
                <CButton
                  onClick={handleClick}
                  variant="outline"
                  size="sm"
                  color="primary.primary"
                  py="20px"
                >
                  Change
                </CButton>
                <input
                  accept="image/*"
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleFileChange}
                />
              </Flex>

              {isPending && <Text>Sending your information</Text>}
              {isError && (
                <Text color="red.500">
                  Error: {error?.response.data.message}
                </Text>
              )}
            </VStack>
            <HStack>
              <CButton
                variant="outline"
                onClick={() => navigate("/admin/profile")}
              >
                Cancel
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                isLoading={isPending || initialLoading}
              >
                Save Changes
              </CButton>
            </HStack>
          </form>
        </Flex>
      </Flex>
    </Box>
  );
}

export default EditProfile;
