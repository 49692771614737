import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteProject as useDeleteProjectMutation } from "services/useProjects";

export const useDeleteProject = () => {
  const { mutate: deleteProjectMutation } = useDeleteProjectMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setError(null);
  };

  const onDelete = async (projectId) => {
    setIsLoading(true);
    setError(null);

    deleteProjectMutation(projectId, {
      onSuccess: () => {
        setIsLoading(false);
        onClose();
        navigate("/admin/projects");
      },
      onError: (e) => {
        setError(e.message);
        setIsLoading(false);
      },
    });
  };

  return { isOpen, onOpen, onClose, onDelete, isLoading, error };
};
