import api from "./apiService";
import { useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Retrieves cities by ID
 *
 * @param {Object} payload
 * @param {string} payload.cityId
 * @returns {Promise<Object>}
 */
const getCities = async ({ cityId }) => {
  const { data } = await api.get(`${API_URLS.cities}/${cityId}`);
  return data?.data || {};
};

export const useGetCities = ({ enabled, cityId }) => {
  return useQuery({
    queryKey: ["cities", cityId],
    enabled,
    queryFn: () => getCities({ cityId }),
  });
};

/**
 * Retrieves states by country name
 *
 * @param {Object} payload
 * @param {string} payload.countryName
 * @returns {Promise<Object>}
 */
const getStates = async ({ countryName }) => {
  const { data } = await api.get(`${API_URLS.states}/${countryName}`);
  return data?.data || {};
};

export const useGetStates = ({ enabled, countryName }) => {
  return useQuery({
    queryKey: ["states", countryName],
    enabled,
    queryFn: () => getStates({ countryName }),
  });
};
