import { Badge, Button, Card, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { ArrowRight2, DocumentText } from "iconsax-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const FormCard = ({ id, name, status, system_default, used_in }) => {
  const navigate = useNavigate();

  return (
    <Card
      p="24px"
      my="24px"
      color="primary.primary"
      cursor="pointer"
      onClick={() => {
        navigate(`form-details?id=${id}&column=General+Information`);
      }}
    >
      <Grid
        alignItems="center"
        justifyContent="space-around"
        templateColumns="repeat(3, 1fr) 0.5fr"
        hideBelow="md"
      >
        <Text>{name}</Text>
        <Stack direction="row">
          {status === "active" && <Badge color="green">Active Form</Badge>}
          {system_default && <Badge color="blue">System Default</Badge>}
        </Stack>
        <Flex gap={1}>
          <DocumentText variant="Bold" /> <Text>Used In</Text> : {used_in || 0}{" "}
          Projects
        </Flex>
        <Button rightIcon={<ArrowRight2 />} fontWeight="normal" variant="ghost">
          View
        </Button>
      </Grid>
      <Flex direction="column" gap={3} hideFrom="md">
        <Flex alignItems="center" justifyContent="space-between">
          <Text>{name}</Text>
          {status === "active" && <Badge color="green">Active Form</Badge>}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          {system_default && <Badge color="blue">System Default</Badge>}
          <Button
            rightIcon={<ArrowRight2 size={20} />}
            fontWeight="normal"
            variant="ghost"
            padding={0}
            size="sm"
          >
            View
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default FormCard;
