import axios from "axios";
import { API_BASE_URL } from "config/apiConfig";

const baseURL = API_BASE_URL;

const instance = axios.create({ baseURL });

instance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
