/* eslint-disable */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useNavigate } from "react-router-dom";

import { useForgetPassword } from "services/useAuth";
import { loginSuccess } from "store/userActions";

// Assets
import illustration from "assets/img/auth/auth.png";

function FortgetPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { colorMode } = useColorMode();

  //------------------

  const dispatch = useDispatch();
  const { mutate: forgetPasswordMutate } = useForgetPassword();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleForgetPassword = async (e) => {
    setIsDisabled(true);
    e.preventDefault();
    forgetPasswordMutate(
      { email: username },
      {
        onSuccess: () => {
          dispatch(
            loginSuccess({
              isAuthenticated: false,
              token: "",
              username: username,
            })
          );

          navigate("/auth/otp?md=3droWssAp_wEn");
        },
        onError: () => {
          setIsDisabled(false);
          setUsername(username);
          setError("Invalid email");
        },
      }
    );
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto" mb="30px">
            <Text color={textColor} fontSize="28px" mb="10px">
              Forgot Password
            </Text>
            <Text fontSize="12px">
              Enter your email for the verification process. We will send a 4
              digits code to your email.
            </Text>
          </Box>
          <form onSubmit={handleForgetPassword}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="ex: myname@example.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={username}
                id="username"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
              />

              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
              >
                Continue
              </CButton>
            </FormControl>
          </form>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <NavLink to="/auth/sign-in">
              <Text as="span" ms="5px" fontWeight="500">
                Back to Sign In
              </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default FortgetPassword;
