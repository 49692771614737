import api from "./apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Adds billing information
 *
 * @param {Object} body
 * @param {string} body.company_name
 * @param {string} body.country
 * @param {string} body.address_line_1
 * @param {string} body.city
 * @param {string} body.state
 * @param {string} body.zip_or_postal_code
 * @returns {Promise<Object>}
 */
const addBillingInfo = async (body) => {
  const response = await api.post(API_URLS.billing_info, body);
  return response;
};

export const useAddBillingInfo = () => {
  return useMutation({
    mutationFn: addBillingInfo,
  });
};

/**
 * Adds a payment method
 *
 * @param {Object} body
 * @param {string} body.card_number
 * @param {string} body.expiration_date
 * @param {string} body.cvv2
 * @param {string} body.card_holder_name
 * @returns {Promise<Object>}
 */
const addPaymentMethod = async (body) => {
  const response = await api.post(API_URLS.payment_method, body);
  return response;
};

export const useAddPaymentMethod = () => {
  return useMutation({
    mutationFn: addPaymentMethod,
  });
};
