import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, SimpleGrid, Text, Flex, Heading } from "@chakra-ui/react";
import Button from "components/fields/Button";
import Card from "components/card/Card";
import Projects from "views/admin/home/components/Projects";
import {
  ArrowLeft2,
  Call,
  DocumentText,
  Edit,
  ProfileCircle,
  Sms,
} from "iconsax-react";
import { useGetExpertById } from "services/useExperts";

export default function ExpertDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const expert = location.state?.expert || {};
  const { data, isLoading } = useGetExpertById({
    enabled: !!expert?.id,
    id: expert?.id,
  });
  const firstName = data?.first_name || expert.first_name || "";
  const lastName = data?.last_name || expert.last_name || "";
  const phone = data?.phone || expert.phone;
  const email = data?.email || expert.email || "";
  const projectsCount = data?.projects_count || 0;
  const projects = data?.projects || [];

  const handleEditExpert = () => {
    const payload = {
      id: expert.id,
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
    };
    if (expert.id) {
      navigate("/admin/edit-expert", { state: { expert: payload } });
    }
  };

  return (
    <Box color="primary.primary">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="500"
          display="flex"
          alignItems="center"
          gap={{ base: 1, md: 4 }}
          cursor="pointer"
          onClick={() => navigate("/admin/experts")}
        >
          <ArrowLeft2 /> Expert Details
        </Heading>
        <Box hideFrom={"md"}>
          <Edit size={24} onClick={handleEditExpert} />
        </Box>
      </Box>

      <Flex gap={2} fontSize={"18px"} alignItems={"center"} mx={4}>
        <ProfileCircle variant="Bold" color="#203764" size={24} />
        <Text fontWeight="medium" fontSize="xl">
          {firstName || lastName ? firstName + lastName : email}
        </Text>
      </Flex>

      <Card mt={4} mx={4} width="auto" py={{ base: 0, md: 8 }}>
        <Flex
          display="flex"
          justifyContent="space-between"
          alignItems={{ base: "initial", md: "center" }}
          direction={{ base: "column", md: "row" }}
        >
          <Flex fontSize={"16px"} gap={2} hideFrom={"md"} py={3}>
            <Flex alignItems={"center"} gap={1} width="72px">
              <Call variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"bold"}>Phone </Text>
            </Flex>
            :<Text>{phone}</Text>
          </Flex>
          <Flex fontSize={"16px"} gap={2} py={3}>
            <Flex alignItems={"center"} gap={1} width="72px">
              <Sms variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"semibold"}>Email</Text>
            </Flex>
            :<Text>{email}</Text>
          </Flex>
          <Flex fontSize={"16px"} gap={2} hideBelow={"md"}>
            <Flex alignItems={"center"} gap={1}>
              <Call variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"bold"}>Phone </Text>
            </Flex>
            :<Text>{phone ? `+1${phone}` : ""}</Text>
          </Flex>
          <Flex fontSize={"16px"} gap={2} py={3}>
            <Flex alignItems={"center"} gap={1}>
              <DocumentText variant="Bold" color="#203764" size={20} />
              <Text fontWeight={"bold"}>Assigned Projects </Text>
            </Flex>
            :<Text>{projectsCount}</Text>
          </Flex>
          <Box fontSize={"16px"} hideBelow={"md"}>
            <Button
              onClick={handleEditExpert}
              variant="outline"
              border="1px"
              px={12}
              py={5}
            >
              Edit
            </Button>
          </Box>
        </Flex>
      </Card>

      <SimpleGrid mx={4}>
        <Projects
          title={"Project List"}
          TitleIcon={<DocumentText variant="Bold" size={24} />}
          gridArea="1 / 2 / 2 / 2"
          projects={projects}
        />
      </SimpleGrid>
    </Box>
  );
}
