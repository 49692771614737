/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "store/userActions";
// Chakra imports
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useNavigate } from "react-router-dom";

import { useSignUpExpert } from "services/useAuth";

// Assets
import illustration from "assets/img/auth/auth.png";
import { Eye, EyeSlash } from "iconsax-react";

function SignUp_Invitee() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  //------------------

  const { mutate: signUpExpertMutate } = useSignUpExpert();
  // next refactor
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const queryParams = new URLSearchParams(location.search);

  const handleSignupInvitee = async (e) => {
    e.preventDefault();
    signUpExpertMutate(
      {
        email: userData.user.username,
        name,
        password,
        password_confirmation: password,
      },
      {
        onSuccess: () => {
          dispatch(
            setUser({
              token: userData.user.token,
              username: userData.user.username,
            })
          );

          navigate("/auth/sign-in");
        },
        onError: () => {
          setError("Invalid username or password");
        },
      }
    );
  };

  useEffect(() => {
    const url_data = queryParams.get("data");
    const token = queryParams.get("token");

    var _data = atob(url_data);
    var data = JSON.parse(_data);

    dispatch(
      setUser({
        isAuthenticated: false,
        token: token,
        username: data.email,
        name: name,
        company: data.company_name,
      })
    );
  }, []);

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Sign Up
            </Text>
            <Text
              mb="10px"
              ms="4px"
              color={textColor}
              fontWeight="400"
              fontSize="md"
            >
              You've been invited to{" "}
              <span style={{ fontWeight: "bold" }}>
                {userData.user.company || ""}
              </span>{" "}
              as an expert
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="400"
              fontSize="md"
            >
              Please complete you sign up process
            </Text>
          </Box>
          <form onSubmit={handleSignupInvitee}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              htmlFor="username"
            >
              Email
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="ex: myname@example.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              isDisabled={true}
              borderRadius="10px"
              value={userData.user.username}
              id="username"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              htmlFor="name"
            >
              Name
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="ex: John Smith"
              mb="24px"
              fontWeight="500"
              size="lg"
              borderRadius="10px"
              value={userData.user.name}
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
              htmlFor="password"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="********"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                borderRadius="10px"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? Eye : EyeSlash}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <VStack justifyContent="space-between" align="center" mb="24px">
              <Flex>
                <Checkbox colorScheme="blue" defaultChecked mr="20px" />

                <Text>
                  By accepting the invitation you will registered as an expert
                  for this company
                </Text>
              </Flex>
              <Flex>
                <Checkbox colorScheme="blue" defaultChecked mr="20px" />
                <Text>
                  By creating an account, you agree to our Terms of Use and
                  Privacy Policy.
                </Text>
              </Flex>
            </VStack>
            <CButton
              fontSize="base"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              bgColor="#203764"
              textColor="#fff"
            >
              Accept Invitation and Create Account
            </CButton>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp_Invitee;
