import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import {
    Flex,
    VStack,
    HStack,
    useColorModeValue,
    Text,
    Card,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    useToast,
} from "@chakra-ui/react";
import CButton from "components/fields/Button";
import { paymentInitialValues, paymentValidationSchema } from "./validationSchema";
import { useAddPaymentMethod } from "services/usePayment";

function PaymentMethodForm({ nextStep }) {
    const toast = useToast();
    const navigate = useNavigate();
    const { mutate, isPending } = useAddPaymentMethod();
    const textColor = useColorModeValue("navy.700", "white");

    const handleSubmit = values => {
        mutate(values, {
            onSuccess: () => {
                nextStep();
            },
            onError: (error) => {
                toast({
                    title: "Error",
                    description: "There was an error adding your payment method. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                // formik.setFieldError("general", error.message || "Update failed");
            },
        })
    };

    return (
        <Card p={8} borderRadius="20px" shadow="md" mb={8} width={{ base: "100%", lg: "90%" }}>
            <Text fontSize="28px" mb={4}>
                Payment Method
            </Text>
            <Formik
                initialValues={paymentInitialValues}
                validationSchema={paymentValidationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <VStack spacing={4} align="start">
                            <Field name="card_number">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.card_number && form.touched.card_number}>
                                        <FormLabel htmlFor="card_number" color={textColor}>
                                            Card Number *
                                        </FormLabel>
                                        <Input {...field} id="card_number" placeholder="1234 1234 1234 1234" type="text" maxLength={16} />
                                        <FormErrorMessage>{form.errors.card_number}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Flex width="100%" alignContent="space-between">
                                <HStack align="start" spacing={5} width="100%">
                                    <Field name="expiration_date">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.expiration_date && form.touched.expiration_date} flex="1" >
                                                <FormLabel htmlFor="expiration_date" color={textColor}>
                                                    Expiration Date *
                                                </FormLabel>
                                                <Input {...field} id="expiration_date" placeholder="MM/YY" />
                                                <FormErrorMessage>{form.errors.expiration_date}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name="cvv2">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.cvv2 && form.touched.cvv2} flex="1">
                                                <FormLabel htmlFor="cvv2" color={textColor}>
                                                    CVV2 *
                                                </FormLabel>
                                                <Input {...field} id="cvv2" placeholder="123" maxLength={3} type="text" />
                                                <FormErrorMessage>{form.errors.cvv2}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </HStack>
                            </Flex>
                            <Field name="card_holder_name">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.card_holder_name && form.touched.card_holder_name}>
                                        <FormLabel htmlFor="card_holder_name" color={textColor}>
                                            Card Holder Name *
                                        </FormLabel>
                                        <Input {...field} id="card_holder_name" placeholder="Full name on card" />
                                        <FormErrorMessage>{form.errors.card_holder_name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </VStack>
                        <Flex justifyContent={"end"} mt={10} gap={4}>
                            <CButton variant="outline" px={{base:'20px', md:'40px'}} onClick={() => navigate("/onboarding/plans")}>
                                Cancel
                            </CButton>
                            <CButton
                                fontSize="base"
                                variant="brand"
                                fontWeight="500"
                                h="50"
                                type="submit"
                                bgColor="#203764"
                                textColor="#fff"
                                isLoading={isPending}
                                px={{base:'20px', md:'40px'}}
                            >
                                Save And Continue
                            </CButton>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default PaymentMethodForm;
