import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Input,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Button,
  useBreakpointValue,
  FormControl,
  FormErrorMessage,
  Select,
  Icon,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { useAuth } from "contexts/AppContext";
import illustration from "assets/img/auth/auth.png";
import DeleteProject from "./delete_project/delete_project";
import { useDeleteProject } from "./delete_project/useDeleteProject";
import { useGetAllCustomers } from "services/useCustomers";
import { useGetAllProjects, useEditProject } from "services/useProjects";
import { ArrowLeft2, Trash } from "iconsax-react";
import { useGetExperts } from "services/useExperts";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

function EditProject() {
  const textColor = useColorModeValue("navy.700", "white");

  const { colorMode } = useColorMode();

  const { mutate: editProjectMutation, isPending } = useEditProject();
  const { data: experts = [] } = useGetExperts({ enabled: true }) || {};
  const navigate = useNavigate();
  const [project, setProject] = useState({ name: "", address: "" });
  const { data: customersData } = useGetAllCustomers({ enabled: true });
  const { data: projectsData, isLoading: projectsLoading } = useGetAllProjects({
    enabled: true,
  });
  const customers = customersData?.data || [];

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
    onDelete: deleteProject,
    error: deleteError,
    isLoading: deleteLoading,
  } = useDeleteProject();

  const projectId = new URLSearchParams(window.location.search).get(
    "projectId"
  );

  useEffect(() => {
    const projects = projectsData || [];

    const filter_projects = projects.filter(
      (project) => project.id == projectId
    );

    if (filter_projects)
      if (filter_projects.length > 0) {
        setProject(filter_projects[0]);
      }
  }, [projectId, projectsData]);

  const handleSubmit = (value) => {
    editProjectMutation(
      {
        id: project.id,
        address: value.address,
        name: value.name,
        expert_id: value.expert_id || null,
        description: "_description",
        customer_id: value.customer_id || null,
      },
      {
        onSuccess: () => {
          navigate("/admin/projects/project-details?projectId=" + project.id);
        },
        onError: () => {
          setError("Error in edit project " + project.id);
        },
      }
    );
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Project name is required"),
    address: Yup.string().required("Project address is required"),
  });

  return (
    <>
      <Flex justifyContent="space-between" mt="4px">
        <HStack
          onClick={handleCancelClick}
          cursor="pointer"
          color="primary.primary"
        >
          <Icon as={ArrowLeft2} fontSize="24px" />
          <Text fontWeight={500} fontSize="xl">
            Edit Project
          </Text>
        </HStack>
        <Button
          onClick={openDeleteModal}
          rightIcon={<Trash />}
          color={{ base: "primary.primary", md: "red" }}
          variant="tertiary"
        >
          {useBreakpointValue({
            base: "",
            md: "Delete this Project",
          })}
        </Button>
      </Flex>
      <Flex
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        mb={{ base: 0, md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: 0, md: "7vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={
            colorMode === "dark" ? "gray.700" : { base: "#F5F5F5", md: "white" }
          }
          p="16px"
          borderRadius={{ base: 0, md: "15px" }}
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          h={{ base: "100%", md: "initial" }}
        >
          <Box me="auto">
            <Text
              color={textColor}
              fontSize={{ base: "2xl", md: "32px" }}
              mb="10px"
            >
              Project Details
            </Text>
            <Text mb="36px" ms="4px" color="text.paragraph" fontSize="">
              Edit your project Information and change th expert or customer
              within this page.
            </Text>
          </Box>
          <Formik
            initialValues={{
              name: project.name || "",
              address: project.address || "",
              expert_id: project.expert?.id || "",
              customer_id: project.customer?.id || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, handleChange }) => (
              <Form>
                <VStack spacing={6} align="start" p={4}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>Project Name *</FormLabel>
                    <Field
                      size="lg"
                      as={Input}
                      id="name"
                      name="name"
                      placeholder="Enter project name"
                      onChange={handleChange}
                      disabled={projectsLoading}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.address && touched.address}>
                    <FormLabel>Project Address *</FormLabel>
                    <Field
                      size="lg"
                      as={Input}
                      id="address"
                      name="address"
                      placeholder="Enter project address"
                      onChange={handleChange}
                      disabled={projectsLoading}
                    />
                    <FormErrorMessage>{errors.address}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Assign an Expert (Optional)</FormLabel>
                    <Field
                      as={Select}
                      size="lg"
                      id="expert_id"
                      name="expert_id"
                      placeholder="Select an expert"
                      disabled={projectsLoading}
                    >
                      {experts?.data?.map((expert) => (
                        <option key={expert.id} value={expert.id}>
                          {expert.name}
                        </option>
                      ))}
                    </Field>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Customer</FormLabel>
                    <Field
                      as={Select}
                      size="lg"
                      id="customer_id"
                      name="customer_id"
                      placeholder="Select a customer"
                      disabled={projectsLoading}
                    >
                      {customers.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.first_name} {customer.last_name}
                        </option>
                      ))}
                    </Field>
                  </FormControl>

                  <HStack spacing={4} w="100%">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => navigate(-1)}
                      size="lg"
                      flex="1"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      flex="3"
                      type="submit"
                      isLoading={isPending}
                      isDisabled={projectsLoading}
                    >
                      Save and Continue
                    </Button>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <DeleteProject
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onDelete={() => deleteProject(projectId)}
          error={deleteError}
          isLoading={deleteLoading}
          projectName={project?.name}
        />
      )}
    </>
  );
}

export default EditProject;
