import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        fontWeight: "500",
        border: "1px solid transparent",
      },
      variants: {
        primary: {
          bg: "primary.primary",
          borderColor: "primary.primary",
          color: "primary.50",
          _active: {
            bg: "primary.pressed",
          },
          _focus: {
            bg: "primary.pressed",
          },
          _hover: {
            bg: "primary.pressed",
          },
          _disabled: {
            bg: "#EDF2F7",
            borderColor: "#EDF2F7",
            color: "#8d9296",
          },
        },
        secondary: {
          bg: "transparent",
          borderColor: "primary.primary",
          color: "primary.primary",
          _active: {
            bg: "secondary.pressed",
            borderColor: "gray.400",
          },
          _focus: {
            bg: "secondary.pressed",
            borderColor: "gray.400",
          },
          _hover: {
            bg: "secondary.pressed",
            borderColor: "gray.400",
          },
          _disabled: {
            borderColor: "gray.100",
            color: "#8d9296",
          },
        },
        tertiary: {
          bg: "transparent",
          color: "primary.primary",
          _active: {
            bg: "secondary.pressed",
          },
          _focus: {
            bg: "secondary.pressed",
          },
          _hover: {
            bg: "secondary.pressed",
          },
          _disabled: {
            color: "#8d9296",
          },
        },
        outline: () => ({
          borderRadius: "16px",
        }),
        brand: (props) => ({
          bg: mode("brand.500", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.400")(props),
          },
        }),
        darkBrand: (props) => ({
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),
      },
    },
  },
};
