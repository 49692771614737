// Chakra imports
import { Box } from "@chakra-ui/react";
// Custom components

// Assets
import React, { useState } from "react";

export default function ProjectStatusBadge(props) {
  const { status } = props;

  const tStatus = (status) => {
    switch (status) {
      case "not_started":
        return "Not Started";
      case "evaluation":
        return "Evaluation";
      case "inspection":
        return "Inspection";
        return;
    }
  };

  return (
    <Box
      borderRadius={"5px"}
      maxWidth="130px"
      px={{ base: "20px" }}
      justifyContent="center"
      py={{ base: "3px" }}
      fontWeight="bold"
      fontSize="sm"
      backgroundColor={
        status === "inspection" || status === "in_progress"
          ? "#FFEECA"
          : status === "evaluation" || status === "awaiting_review"
            ? "#EBEFFF"
            : status === "not_started"
              ? "#FEECEE"
              : status === "completed"
                ? "#E0FCFF"
                : null
      }
      textColor={
        status === "inspection" || status === "in_progress"
          ? "#6B4F00"
          : status === "evaluation" || status === "awaiting_review"
            ? "#295BFF"
            : status === "not_started"
              ? "#F2415A"
              : status === "completed"
                ? "#27BE69"
                : null
      }
    >
      {tStatus(status)}
    </Box>
  );
}
