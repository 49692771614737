import { useMemo } from "react";
import { useGetExperts } from "services/useExperts";

export const useExpertsSearch = (searchText) => {
  const { data: experts = [] } = useGetExperts({
    enabled: searchText?.length > 0,
  });

  const filteredExperts = useMemo(() => {
    const handleSearch = (searchTerm) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      return experts.data.filter((item) => {
        return (
          item.email?.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.company?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.expert?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.customer?.first_name
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm) ||
          item.customer?.last_name?.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });
    };

    return searchText ? handleSearch(searchText, experts.data) : experts.data;
  }, [experts, searchText]);

  return filteredExperts;
};
