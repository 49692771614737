import api from "./apiService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Edits company profile
 *
 * @param {Object} payload
 * @param {string} payload.company_name
 * @param {string} payload.company_address
 * @param {string} payload.company_phone
 * @param {string} payload.description
 * @param {File} payload.company_logo
 * @returns {Promise<Object>}
 */
const editProfile = async (payload) => {
  const formData = new FormData();
  formData.append("name", payload.company_name);
  formData.append("address", payload.company_address);
  formData.append("phone_number", payload.company_phone);
  formData.append("description", payload.description);

  if (payload.company_logo) {
    formData.append("logo", payload.company_logo);
  }

  const response = await api.post(`${API_URLS.edit_profile}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const useEditProfile = () => {
  return useMutation({
    mutationFn: editProfile,
  });
};

/**
 * Gets user information
 *
 * @returns {Promise<Object>}
 */
export const getUser = async ({ token }) => {
  const response = await api.get(API_URLS.get_user, {
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });
  return response;
};

export const useGetUser = ({ enabled = true, staleTime }) => {
  return useQuery({
    queryKey: ["user"],
    enabled,
    queryFn: getUser,
    staleTime: staleTime,
  });
};
