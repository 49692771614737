import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "services/useProfile";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { userInfo } from "store/userActions";

function GoogleSignUpCallback() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleSignUpGoogle = async (code) => {
    const { data: userData } = await queryClient.fetchQuery({
      queryKey: ["user"],
      queryFn: () => getUser({ token: code }),
      staleTime: 0,
    });

    if (!userData.data) return;

    dispatch(userInfo(userData.data, code));

    navigate("/onboarding/add-company");
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      handleSignUpGoogle(code);
    }
  }, [navigate]);

  return <div>Loading...</div>;
}

export default GoogleSignUpCallback;
