import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Stack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Spinner,
  Icon,
  Skeleton,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from "@chakra-ui/react";
import Pagination from "components/pagination";
import {
  ArrowRight2,
  Call,
  ProfileCircle,
  DocumentText,
  Add,
  Sms,
} from "iconsax-react";
import { useGetExperts } from "services/useExperts";
import { SearchIcon } from "@chakra-ui/icons";
import { useExpertsSearch } from "./useExpertsSearch";

const TableHeaders = [
  { text: "First Name", icon: ProfileCircle },
  { text: "Last Name", icon: ProfileCircle },
  { text: "Email", icon: Sms },
  { text: "Phone Number", icon: Call },
  { text: "Assigned Projects", icon: DocumentText },
];

export default function Experts() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { data, isLoading } = useGetExperts({ enabled: true });
  const experts = data?.data || [];
  const [searchText, setSearchText] = useState("");

  const textColor = useColorModeValue("navy.700", "white");
  const navigate = useNavigate();

  const findExpert = useExpertsSearch(searchText);
  const filteredExperts = searchText.length > 0 ? findExpert : experts;

  const handleInviteExpert = () => {
    navigate("/onboarding/invite-experts");
  };

  const handleExpertDetails = (expert) => {
    navigate("/admin/experts/expert-details", { state: { expert } });
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const { total, per_page } = data?.meta || {};

    if (total && per_page) {
      const totalItems = data.meta.total;
      const perPage = data.meta.per_page || 10;
      setTotalPages(Math.ceil(totalItems / perPage));
    }
  }, [data]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight={500}
          color={"#203764"}
        >
          Experts
        </Heading>
        <Button
          size="lg"
          rightIcon={<Add />}
          variant="primary"
          onClick={handleInviteExpert}
          hideBelow="md"
        >
          Invite Expert
        </Button>
        <Button
          variant="tertiary"
          onClick={handleInviteExpert}
          hideFrom="md"
          backgroundColor="transparent"
          pr="0"
        >
          <Add size={26} />
        </Button>
      </Box>

      <Flex
        alignItems="start"
        width={{ base: "auto", md: "400px" }}
        gap={2}
        mx={{ base: 4, md: 0 }}
      >
        <FormControl>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" boxSize={6} mt={1} ml={3} />
            </InputLeftElement>
            <Input
              bgColor="#fff"
              fontSize="sm"
              paddingLeft="45px"
              type="text"
              placeholder="Search"
              size="lg"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              h="44px"
            />
          </InputGroup>
        </FormControl>
      </Flex>

      <Box m={{ base: 4, md: 0 }}>
        <Box
          overflowX="auto"
          display={{ base: "none", md: "block" }}
          mt={8}
          p={0}
          borderRadius={15}
          backgroundColor="white"
          boxShadow="sm"
        >
          <TableContainer>
            <Table variant="simple" colorScheme="brand" size="sm">
              <Thead h={16}>
                <Tr>
                  {TableHeaders.map((item, index) => (
                    <Th
                      bg="#EBEFFF"
                      textColor={textColor}
                      textTransform={"none"}
                      fontSize="sm"
                      fontWeight="500"
                    >
                      <Flex alignItems="center">
                        <Icon
                          as={item.icon}
                          variant="Bold"
                          w="16px"
                          h="16px"
                          mr={2}
                        />
                        <Text>{item.text}</Text>
                      </Flex>
                    </Th>
                  ))}
                  <Th
                    bg="#EBEFFF"
                    textColor={textColor}
                    textTransform={"none"}
                    fontSize="sm"
                    fontWeight="500"
                  ></Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <Tr key={index} h={12}>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                      <Td h="64px">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))
                ) : filteredExperts.length > 0 ? (
                  filteredExperts.map((item) => (
                    <Tr
                      h={16}
                      key={item.id}
                      _hover={{ bg: "#f7f7f7" }}
                      sx={{ borderBottom: "1px solid black" }}
                      onClick={() => handleExpertDetails(item)}
                      cursor="pointer"
                    >
                      <Td>{item.first_name || ""}</Td>
                      <Td>{item.last_name || ""}</Td>
                      <Td>{item.email || ""}</Td>
                      <Td>{item.phone ? `+1${item.phone}` : ""}</Td>
                      <Td>{item.project_count || 0}</Td>
                      <Td>
                        <Button
                          onClick={() => handleExpertDetails(item)}
                          href="#"
                          py="0"
                          variant="tertiary"
                        >
                          <Flex
                            alignItems="center"
                            fontSize="sm"
                            fontWeight="normal"
                          >
                            <Text>View </Text>
                            <Icon as={ArrowRight2} w="16px" h="16px" ml={1} />
                          </Flex>
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={5} textAlign="center" py={10}>
                      No Expert Is Added
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {experts && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNextPage={handleNextPage}
                onPrevPage={handlePreviousPage}
                onPageChange={handlePageChange}
              />
            )}
          </TableContainer>
        </Box>

        <Box display={{ base: "block", md: "none" }}>
          <Stack spacing={4}>
            {isLoading ? (
              <Box
                borderRadius="lg"
                overflow="hidden"
                p={6}
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size="lg" color={textColor} />
              </Box>
            ) : filteredExperts.length > 0 ? (
              filteredExperts.map((item) => (
                <Box
                  key={item.id}
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  color="primary.primary"
                  bg="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => handleExpertDetails(item)}
                >
                  <Heading size="sm">
                    <Flex alignItems="center">
                      <Icon
                        as={ProfileCircle}
                        variant="Bold"
                        w="16px"
                        h="16px"
                        mr={1}
                      />
                      <Text>
                        {item.first_name && item.last_name
                          ? `${item.first_name} ${item.last_name}`
                          : item.email}
                      </Text>
                    </Flex>
                  </Heading>
                  <Button
                    key={item.id}
                    onClick={() => handleExpertDetails(item)}
                    href="#"
                    pr="0"
                    size="sm"
                    variant="tertiary"
                  >
                    <Flex alignItems="center">
                      <Text>View </Text>
                      <Icon as={ArrowRight2} w="16px" h="16px" ml={1} />
                    </Flex>
                  </Button>
                </Box>
              ))
            ) : (
              <Box
                borderRadius="lg"
                overflow="hidden"
                p={6}
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                No Expert Is Added
              </Box>
            )}
            {experts && (
              <Box
                borderRadius="lg"
                overflow="hidden"
                p={1}
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePreviousPage}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
