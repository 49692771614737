import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutDone } from "store/userActions";

const AuthContext = createContext();

export const AppContext = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = () => {
    setIsAuthenticated(false);
    dispatch(logoutDone());
    navigate("/auth/sign-in");
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
