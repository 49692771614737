// src/config/apiConfig.js
export const API_BASE_URL = "https://api.repolet.dev/api/v1";

export const API_URLS = {
  login: "/login",
  logout: `${API_BASE_URL}/logout`,
  register: "/register",
  otp: "/verify-otp",
  forgetPassword: "/password/forgot",
  password_verify_otp: "/password/verify-otp",
  setـnew: "/password/set-new",
  onboarding: "/company/onboarding/info",
  invite_expert: "/company/onboarding/invite-experts",
  signup_expert: "/expert/signup-with-invite",
  expert: "/expert",
  experts: "/experts",
  get_user: "/user",
  google_signup_callback: "/auth/google/signup/callback",
  google_signin_callback: "/auth/google/signin/callback",
  add_projects: "/projects",
  edit_projects: "/projects",
  all_projects: "/projects",
  all_customers: "/customers",
  customer: "/customer/",
  customers: "/customers",
  edit_profile: "/company/profile",
  payment_method: "/company/payments",
  billing_info: "/company/billing-info",
  states: "/location/states",
  cities: "/location/cities",
  forms: "/forms",
};
