import React from "react";
import { Box, Button, Heading, Skeleton, Stack } from "@chakra-ui/react";
import { Add } from "iconsax-react";
import FormCard from "./components/FormCard";
import { useNavigate } from "react-router-dom";
import { useGetAllForms } from "services/useForms";

export default function Forms() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllForms({ enabled: true });

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={{ base: "#fff", md: "transparent" }}
        px={{ base: "16px", md: "0" }}
        h="48px"
        mb={4}
      >
        <Heading
          as="h1"
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight={500}
          color={"#203764"}
        >
          Inspection Forms
        </Heading>
        <Button
          size="lg"
          rightIcon={<Add />}
          variant="primary"
          hideBelow="md"
          fontWeight="medium"
          onClick={() => navigate("add-form")}
          px="24px"
        >
          Add New Form
        </Button>
        <Button
          variant="tertiary"
          onClick={() => navigate("add-form")}
          hideFrom="md"
          backgroundColor="transparent"
          pr="0"
        >
          <Add size={26} />
        </Button>
      </Box>

      <Box m={{ base: 4, md: 0 }}>
        {isLoading ? (
          <Stack spacing={8} mt={6}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Skeleton height="80px" borderRadius="md" key={index} />
            ))}
          </Stack>
        ) : (
          data?.forms?.map(({ id, name, status, system_default, used_in }) => (
            <FormCard
              id={id}
              name={name}
              status={status}
              system_default={system_default}
              numberOfProjects={used_in}
            />
          ))
        )}
      </Box>
    </Box>
  );
}
