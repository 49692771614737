import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userInfo } from "store/userActions";
import { getUser } from "services/useProfile";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

const AUTH_TYPE = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
};

function GoogleSigninCallback() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleAuthGoogle = async (type, token) => {
    const { data: userData } = await queryClient.fetchQuery({
      queryKey: ["user"],
      queryFn: () => getUser({ token }),
      staleTime: 0,
    });

    if (!userData.data) return;

    dispatch(userInfo(userData.data, token));

    if (type === AUTH_TYPE.SIGN_IN) {
      navigate("/admin/home");
    }

    if (type === AUTH_TYPE.SIGN_UP) {
      navigate("/onboarding/add-company");
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("t");
    const status = new URLSearchParams(window.location.search).get("s");
    const flow = new URLSearchParams(window.location.search).get("f");
    const error = new URLSearchParams(window.location.search).get("e");

    if (flow == "in") {
      if (status == "t") {
        handleAuthGoogle(AUTH_TYPE.SIGN_IN, token);
      }
      if (status == "f") {
        navigate("/auth/sign-in");
      }
    } else if (flow == "up") {
      if (status == "t") {
        handleAuthGoogle(AUTH_TYPE.SIGN_UP, token);
      }
      if (status == "f" && error == "409") {
        alert("user exist!");
        navigate("/auth/sign-in");
      }
    }
  }, [navigate]);

  return (
    <div style={{ margin: "0 auto", width: "100px", marginTop: "200px" }}>
      Redirecting ...
    </div>
  );
}

export default GoogleSigninCallback;
