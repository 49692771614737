import React from "react";
import { useLocation } from "react-router-dom";
import PaymentLayout from "layouts/payment";
import PlanCard from "components/payment/PlanCard";
import TotalCard from "components/payment/TotalCard";
import {
  Box,
  Flex,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Card,
} from "@chakra-ui/react";
import PaymentMethodForm from "components/payment/forms/paymentMethodForm";
import BillingAddressForm from "components/payment/forms/billingAddressForm";

const steps = [
  { title: "Payment Method", description: "" },
  { title: "Billing Address", description: "" },
  { title: "Confirmation", description: "" },
];

function PaymentDetails() {
  const location = useLocation();
  const planData = location.state || {};
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const nextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <PaymentLayout backText="Payment Details" hasBackButton>
      <Flex justifyContent={'center'} flexDirection={{ base: 'column-reverse', lg: 'row' }} flexWrap="wrap" gap={'6'} mt={10}>
        <Flex flex={{ base: 1, lg: 2 }} mb={{ base: 10, lg: 0 }} direction={'column'} alignItems={{ base: 'center', lg: 'end' }}>
          <Card p={8} borderRadius="20px" shadow="md" mb={8} width={{ base: "100%", lg: "90%" }}>
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={step.title} onClick={() => setActiveStep(index)}>
                  <VStack>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle textAlign={{base:'center'}}>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </VStack>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Card>

          {activeStep === 0 && <PaymentMethodForm nextStep={nextStep} />}
          {activeStep === 1 && <BillingAddressForm nextStep={nextStep}/>}
          {activeStep === 2 && (
            <Card p={8} borderRadius="20px" shadow="md" mb={8} width={{ base: "100%", lg: "90%" }}>
              <Box textAlign="center">Confirmation Step Content</Box>
            </Card>
          )}
        </Flex>

        <Flex flex="1" direction={'column'} gap={'6'} mb={4} alignItems={{ base: 'center', lg: "flex-start" }}>
          <PlanCard {...planData} minimized />
          <TotalCard Subtotal={planData?.price || ''} total={planData?.price || ''}/>
        </Flex>
      </Flex>
    </PaymentLayout>
  );
}

export default PaymentDetails;
