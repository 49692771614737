import api from "./apiService";
import { useMutation } from "@tanstack/react-query";
import { API_URLS } from "config/apiConfig";

/**
 * Changes password
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.otp
 * @param {string} payload.password
 * @param {string} payload.password_confirmation
 * @returns {Promise<Object>}
 */
const changePassword = async (payload) => {
  const response = await api.post(API_URLS.setـnew, payload);
  return response;
};

export const useChangePassword = () => {
  return useMutation({
    mutationFn: changePassword,
  });
};

/**
 * Forgets password
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @returns {Promise<Object>}
 */
const forgetPassword = async ({ email }) => {
  const response = await api.post(API_URLS.forgetPassword, { email });
  return response;
};

export const useForgetPassword = () => {
  return useMutation({
    mutationFn: forgetPassword,
  });
};

/**
 * Signs in
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.password
 * @returns {Promise<Object>}
 */
const signIn = async ({ email, password }) => {
  const response = await api.post(API_URLS.login, {
    email,
    password,
  });
  return response;
};

export const useSignIn = () => {
  return useMutation({
    mutationFn: signIn,
  });
};

/**
 * Signs in with Google
 *
 * @param {Object} payload
 * @param {string} payload.code
 * @returns {Promise<Object>}
 */
const signInGoogle = async ({ code }) => {
  const response = await api.post(API_URLS.google_signin_callback, { code });
  return response;
};

export const useSignInGoogle = () => {
  return useMutation({
    mutationFn: signInGoogle,
  });
};

/**
 * Signs up
 *
 * @param {Object} payload
 * @param {string} payload.name
 * @param {string} payload.email
 * @param {string} payload.password
 * @param {string} payload.password_confirmation
 * @param {string} payload.company_name
 * @returns {Promise<Object>}
 */
const signUp = async (payload) => {
  const response = await api.post(API_URLS.register, payload);
  return response;
};

export const useSignUp = () => {
  return useMutation({
    mutationFn: signUp,
  });
};

/**
 * Signs up as an expert
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.name
 * @param {string} payload.password
 * @param {string} payload.password_confirmation
 * @returns {Promise<Object>}
 */
const signUpExpert = async (payload) => {
  const response = await api.post(API_URLS.signup_expert, payload);
  return response;
};

export const useSignUpExpert = () => {
  return useMutation({
    mutationFn: signUpExpert,
  });
};

/**
 * Signs up with Google
 *
 * @param {Object} payload
 * @param {string} payload.code
 * @returns {Promise<Object>}
 */
const signUpGoogle = async ({ code }) => {
  const response = await api.post(API_URLS.google_signup_callback, { code });
  return response;
};

export const useSignUpGoogle = () => {
  return useMutation({
    mutationFn: signUpGoogle,
  });
};

/**
 * Verifies OTP
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.otp
 * @returns {Promise<Object>}
 */
const verifyOTP = async ({ email, otp }) => {
  const response = await api.post(API_URLS.otp, { email, otp });
  return response;
};

export const useVerifyOTP = () => {
  return useMutation({
    mutationFn: verifyOTP,
  });
};

/**
 * Verifies password OTP
 *
 * @param {Object} payload
 * @param {string} payload.email
 * @param {string} payload.otp
 * @returns {Promise<Object>}
 */
const verifyPasswordOTP = async ({ email, otp }) => {
  const response = await api.post(API_URLS.password_verify_otp, { email, otp });
  return response;
};

export const useVerifyPasswordOTP = () => {
  return useMutation({
    mutationFn: verifyPasswordOTP,
  });
};
